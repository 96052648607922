var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
export var addCustomRetailers = function (retailers) {
    // Remove some retailers
    var removeRetailers = [
        "Bra Bil",
        // "Göinge Bil",
        "Kristianstads Automobil",
        "Volvo Bil",
        "Bil-Bengtsson",
        "Bildepån Varberg",
    ];
    // console.log("REMOVING RETAILERS", removeRetailers);
    var filteredRetailers = retailers.filter(function (r) { return !removeRetailers.includes(r.branchName); });
    var bilmansson = filteredRetailers.find(function (r) { return r.branchName === "Bilmånsson"; });
    // Rename this retailer
    var volvoBil = filteredRetailers.find(function (r) { return r.branchName === "Volvo Bil"; });
    if (volvoBil) {
        volvoBil.branchName = "Volvo Bil i Göteborg AB";
        volvoBil.townNames = [
            "Landvetter",
            "Lundby",
            "Sörred",
            "Tuve",
        ];
    }
    // Remove towns from this retailer
    var skobes = filteredRetailers.find(function (r) { return r.branchName === "Skobes"; });
    if (skobes.townNames.includes("Högsby")) {
        skobes.townNames = skobes.townNames.filter(function (town) { return town !== "Högsby"; });
    }
    if (skobes.townNames.includes("Flen")) {
        skobes.townNames = skobes.townNames.filter(function (town) { return town !== "Flen"; });
    }
    // Rename this retailer
    var brandtBil = filteredRetailers.find(function (r) { return r.branchName === "Bröderna Brandt Personbilar"; });
    if (brandtBil) {
        brandtBil.branchName = "Brandt Personbilar AB";
    }
    if (bilmansson) {
        // add "Kristianstad" to townNames
        if (!bilmansson.townNames.includes("Kristianstad")) {
            bilmansson.townNames.push("Kristianstad");
        }
    }
    return __spreadArray(__spreadArray([], filteredRetailers.map(function (retailer) { return (__assign(__assign({}, retailer), { redirect: false, showTownNames: true, url: "", description: "" })); })), [
        {
            id: "SE1076",
            name: "volvo.bilia.se",
            branchName: "Bilia",
            hasWheelChange: true,
            hasServiceBooking: true,
            hasWindowRepair: true,
            showTownNames: false,
            redirect: true,
            url: "https://boka.bilia.se/",
            urlWindowRepair: "https://www.bilia.se/verkstad/bilglas/?currentCarBrand=Volvo",
            urlWheelChange: "https://www.bilia.se/verkstad/dacktjanster/?currentCarBrand=Volvo",
            description: "Gå vidare till en separat bokningstjänst på bilia.se.",
            townNames: [
                "Lerum",
                "Sävedalen",
                "Kungsbacka",
                "Almedal",
                "Göteborg",
                "Hisingen",
                "Stenungsund",
                "Solna",
                "Alvik",
                "Jakobsberg",
                "Vinsta",
                "Kista",
                "Lidingö",
                "Haninge",
                "Nacka",
                "Stockholm",
                "Tyresö",
                "Täby",
                "Södertälje",
                "Segeltorp",
                "Södermalm",
                "Tumba",
                "Västerås",
                "Hedemora",
                "Arboga",
                "Avesta",
                "Köping",
                "Eskilstuna",
                "Fagersta",
                "Sala",
                "Strängnäs",
                "Jägerso",
                "Limhamn",
                "Värnhem",
                "Lund",
                "Trelleborg",
            ],
        },
        {
            id: "SERETAIL",
            name: "volvo.retail.se",
            branchName: "Volvo Car Retail",
            hasWheelChange: true,
            hasServiceBooking: true,
            hasWindowRepair: true,
            showTownNames: false,
            redirect: true,
            url: "https://www.volvocarretail.se/verkstad-service/boka",
            urlWindowRepair: "https://www.volvocarretail.se/verkstad-service/glasverkstad/boka#/WindowRepair/Booking",
            urlWheelChange: "https://www.volvocarretail.se/dack/hjulskifte/boka#/booking",
            description: "Gå vidare till en separat bokningstjänst på volvocarretail.se.",
            townNames: [
                "Arlandastad",
                "Stockholm",
                "Länna",
                "Kista",
                "Hammarby",
                "Tierp",
                "Bålsta",
                "Enköping",
                "Göteborg",
                "Kungsängen",
                "Landvetter",
                "Upplands Väsby",
                "Vallentuna",
            ],
        },
        {
            id: "MECHANUM",
            name: "volvo.mechanum.se",
            branchName: "Mechanum",
            hasWheelChange: true,
            hasServiceBooking: true,
            hasWindowRepair: true,
            showTownNames: false,
            redirect: true,
            url: "https://www.mechanum.com/boka/tjanst/",
            urlWindowRepair: "https://www.mechanum.com/boka/tjanst/",
            urlWheelChange: "https://www.mechanum.com/boka/tjanst/",
            description: "Gå vidare till en separat bokningstjänst på mechanum.com.",
            townNames: ["Göteborg", "Malmö"],
        },
        // {
        //     id: "ALLTIBIL",
        //     name: "volvo.alltibil.se",
        //     branchName: "Allt i Bil",
        //     hasWheelChange: true,
        //     hasServiceBooking: true,
        //     hasWindowRepair: true,
        //     showTownNames: false,
        //     redirect: true,
        //     url: "https://www.alltibil.se/bokning",
        //     description:
        //         "Gå vidare till en separat bokningstjänst på alltibil.se.",
        //     townNames: ["Göteborg", "Kungälv"],
        // },
        // {
        //     id: "LIDINGÖBILTEKNIK",
        //     name: "volvo.lidingobilteknik.se",
        //     branchName: "Min Bil Lidingö",
        //     hasWheelChange: true,
        //     hasServiceBooking: true,
        //     hasWindowRepair: true,
        //     showTownNames: false,
        //     redirect: true,
        //     url: "http://www.lidingobilteknik.se/contact",
        //     description:
        //         "Gå vidare till en separat bokningstjänst på lidingobilteknik.se.",
        //     townNames: ["Lidingö"],
        // },
        // {
        //     id: "GÖINGEBIL",
        //     name: "volvo.goingebil.se",
        //     branchName: "Göinge Bil",
        //     hasWheelChange: true,
        //     hasServiceBooking: true,
        //     hasWindowRepair: true,
        //     showTownNames: false,
        //     redirect: true,
        //     url: "https://goingebil.se/boka-tid/verkstadsbesok",
        //     description:
        //         "Gå vidare till en separat bokningstjänst på goingebil.se.",
        //     townNames: ["Hässleholm", "Markaryd", "Osby", "Älmhult"],
        // },
        {
            id: "WERKSTA",
            name: "volvo.werksta.se",
            branchName: "Werksta",
            hasWheelChange: true,
            hasServiceBooking: true,
            hasWindowRepair: true,
            showTownNames: false,
            redirect: true,
            url: "https://www.werksta.se/volvo-services/boka-service-reparation/#/ServiceBooking/Service",
            urlWindowRepair: "https://www.werksta.se/volvo-services/boka-glasreparation/#/WindowRepair/Booking",
            urlWheelChange: "https://www.werksta.se/volvo-services/boka-hjulskifte/#/booking",
            description: "Gå vidare till en separat bokningstjänst på werksta.se.",
            townNames: ["Handen"],
        },
    ]).sort(function (a, b) {
        var nameA = a.branchName.toUpperCase();
        var nameB = b.branchName.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
};
