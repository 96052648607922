import React, { useEffect, useState } from "react";
import { Card, CardContent, Text, TextInput } from "vcc-ui";
import { addCustomRetailers } from "../../helpers/addCustomRetailers";
import "./styles.scss";
export default function (props) {
    var _a = useState(""), retailer = _a[0], setRetailer = _a[1];
    var _b = useState(""), retailerFilter = _b[0], setRetailerFilter = _b[1];
    var allRetailers = addCustomRetailers(props.retailers);
    // Timer for Volvo Bil disclaimer
    var outgoingDate = new Date("2022-01-01");
    var currentDate = new Date();
    useEffect(function () {
        var _a;
        if (retailer !== "") {
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: "EventTracking",
                    category: "Service Booking",
                    action: "Select Reseller",
                    label: (_a = allRetailers.find(function (el) { return el.id === retailer; })) === null || _a === void 0 ? void 0 : _a.branchName,
                });
            }
            props.onSelectRetailer(retailer);
        }
    }, [retailer]);
    var filterRetailers = function (retailer) {
        var regex = new RegExp(retailerFilter.toLowerCase(), "g");
        var branchNameMatch = regex.test(retailer.branchName.toLowerCase());
        // console.log(Array.isArray(retailer.townNames));
        var townNameMatch = Array.isArray(retailer === null || retailer === void 0 ? void 0 : retailer.townNames)
            ? retailer.townNames.some(function (town) { return regex.test(town.toLowerCase()); })
            : false;
        return branchNameMatch || townNameMatch;
    };
    return (React.createElement("div", { className: "retailers-container" },
        React.createElement(TextInput, { value: retailerFilter, className: "search-retailers", tabIndex: 1, label: "S\u00F6k stad eller Volvohandlare", onChange: function (e) {
                return setRetailerFilter(e.target.value);
            } }),
        React.createElement("div", null, allRetailers.filter(filterRetailers).map(function (retailer, idx) {
            return (React.createElement(Card, { className: "retailer-card", tabIndex: idx + 1, key: retailer.id, href: (retailer === null || retailer === void 0 ? void 0 : retailer.redirect) &&
                    props.type === "windowrepair"
                    ? retailer.urlWindowRepair
                    : props.type === "wheelchange"
                        ? retailer.urlWheelChange
                        : retailer.url, onClick: function () {
                    return retailer.branchName !== "Bilia" &&
                        setRetailer(retailer.id);
                }, onKeyDown: function (e) {
                    if (e.key === "Enter" &&
                        retailer.branchName !== "Bilia") {
                        setRetailer(retailer.id);
                    }
                } },
                React.createElement(CardContent, null,
                    React.createElement(Text, { subStyle: "emphasis" }, retailer.branchName),
                    retailer.showTownNames && (React.createElement(Text, { className: "town-names", variant: "bates" }, Array.isArray(retailer === null || retailer === void 0 ? void 0 : retailer.townNames) &&
                        retailer.townNames.join(", "))),
                    retailer.description && (React.createElement(Text, { className: "town-names", variant: "bates" }, retailer.description)),
                    retailer.branchName ===
                        "Volvo Bil i Göteborg AB" &&
                        currentDate.getTime() <
                            outgoingDate.getTime() && (React.createElement(Text, { className: "town-names", variant: "bates" }, "F\u00F6r dig som \u00E4r anst\u00E4lld p\u00E5 Volvo, Geely, Polestar")))));
        }))));
}
