import React, { useState, useEffect } from "react";
import { Text, Card, CardContent } from "vcc-ui";
import "./styles.scss";
export default function (props) {
    var _a = useState(""), service = _a[0], setService = _a[1];
    useEffect(function () {
        // Step is currently skipped
        props.onSelectService("sb");
        // if (service === "") {
        //     props.onSelectService(null);
        // } else {
        //     props.onSelectService(service as ServiceId);
        // }
    }, [service]);
    return (React.createElement("div", { className: "services-container" }, props.retailer.hasWheelChange ||
        props.retailer.hasServiceBooking ||
        props.retailer.hasWindowRepair ? (React.createElement(React.Fragment, null,
        props.retailer.hasServiceBooking && (React.createElement(Card, { className: "service-card", onClick: function () { return setService("sb"); }, tabIndex: 1, onKeyDown: function (e) {
                if (e.key === "Enter") {
                    setService("sb");
                }
            } },
            React.createElement(CardContent, null,
                React.createElement(Text, { subStyle: "emphasis" }, "Boka Service")))),
        props.retailer.hasWheelChange && (React.createElement(Card, { className: "service-card", onClick: function () { return setService("wc"); }, tabIndex: 2, onKeyDown: function (e) {
                if (e.key === "Enter") {
                    setService("wc");
                }
            } },
            React.createElement(CardContent, null,
                React.createElement(Text, { subStyle: "emphasis" }, "Boka D\u00E4ckbyte")))),
        props.retailer.hasWindowRepair && (React.createElement(Card, { className: "service-card", onClick: function () { return setService("wr"); }, tabIndex: 3, onKeyDown: function (e) {
                if (e.key === "Enter") {
                    setService("wr");
                }
            } },
            React.createElement(CardContent, null,
                React.createElement(Text, { subStyle: "emphasis" }, "Boka F\u00F6nsterreparation")))))) : (React.createElement(Text, null,
        "Finns tyv\u00E4rr inga tj\u00E4nster hos: ",
        props.retailer.id))));
}
