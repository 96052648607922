import React, { useEffect, useRef } from "react";
import { Text, Spacer } from "vcc-ui";
import "./styles.scss";
export default function (props) {
    var theIframe = useRef(null);
    var onWindowMessage = function (e) {
        if (typeof e.data === "string") {
            if (e.data.length > 25) {
                if (e.data.substring(0, 25) === "vcs-tacdis-iframe-height:") {
                    var theHeight = Math.round(parseFloat(e.data.substring(25)));
                    if (theIframe && theIframe.current) {
                        theIframe.current.style.height = theHeight + 20 + "px";
                    }
                }
            }
        }
    };
    useEffect(function () {
        window.addEventListener("message", onWindowMessage, false);
        return function () {
            window.removeEventListener("message", onWindowMessage);
        };
    }, []);
    var params = new URLSearchParams();
    params.append("target", window.location.href);
    params.append("retailer", props.retailer.id);
    var url = null;
    if (props.serviceId === "wc") {
        url = props.endpoint + "/wheelchange.php?" + params.toString();
    }
    else if (props.serviceId === "sb") {
        url = props.endpoint + "/servicebooking.php?" + params.toString();
    }
    else if (props.serviceId === "wr") {
        url = props.endpoint + "/windowrepair.php?" + params.toString();
    }
    return (React.createElement("div", { className: "tacdisIframeComponent" },
        React.createElement(Text, { variant: "hillary", subStyle: "emphasis" }, props.retailer.branchName),
        React.createElement(Spacer, null),
        url !== null && (React.createElement("iframe", { ref: theIframe, src: url, seamless: true }))));
}
