import React from "react";
import { Arrow, Text } from "vcc-ui";
import "./styles.scss";
export var ProgressIndicator = function (_a) {
    var displayPage = _a.displayPage;
    return (React.createElement("div", { className: "progress-container" },
        React.createElement(Text, { variant: "bates", subStyle: displayPage === "retailers" ? "emphasis" : "default" }, "V\u00E4lj Volvohandlare"),
        React.createElement(Arrow, { direction: "right", size: 25 }),
        React.createElement(Text, { variant: "bates", subStyle: displayPage === "iframe" ? "emphasis" : "default" }, "Boka tid")));
};
